<template>
    <button 
        @click="toggleMenu"
        @focus="showMenu"
        @blur="hideMenu"
        @mouseenter="showMenu"
        @mouseleave="hideMenu"
        class="mega-menu-nav-button"
        :aria-expanded="expanded.toString()"
        aria-haspopup="true"
        type="button"
        @keydown.esc="hideMenu"
        >
        <slot></slot>
    </button>

</template>

<script>
    export default {
       props: {
           target: String,
           link: Boolean,
       },
       data() {
           return {
              megaMenu: undefined,
              expanded: false
           }
       },
       methods: {
           toggleMenu: function () {
                this.megaMenu.toggle();
           },
           hideMenu: function () {
               if (screen.width < 640) {
                   return
               }
                this.megaMenu.hide();
                this.expanded = false;
           },
           showMenu: function () {
               if (screen.width < 640) {
                   return
               }
                this.megaMenu.show();
                this.expanded = true;
           }
       },
       mounted() {
           this.megaMenu = document.getElementById(this.target);
       } 
    }
</script>
