<template>
    <div>
    <hooper :settings="settings" ref="carousel">
        <slot ></slot>
        <hooper-navigation slot="hooper-addons">
            <svg width="20px" height="30px" viewBox="0 0 20 30" slot="hooper-prev" @click="resetCards()">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Carousel" transform="translate(-30.000000, -473.000000)" fill="#FFFFFF">
                        <path d="M30.9195395,502.592221 L39.665361,502.592221 C39.9994426,502.592221 40.3114576,502.42539 40.4969858,502.147559 L49.6252436,488.47789 C49.8499125,488.141446 49.8497295,487.702777 49.6247799,487.36652 L40.4970585,473.722323 C40.3114629,473.444893 39.9996834,473.278354 39.6658974,473.278354 L30.967872,473.278354 C30.6593758,473.278354 30.3760958,473.448708 30.2314664,473.721201 C30.0859745,473.995319 30.0974459,474.326168 30.2615744,474.589551 L38.5780606,487.935288 L38.5780606,487.935288 L30.315192,501.194983 C30.1439632,501.46976 30.1105106,501.80862 30.2247055,502.111574 C30.3337183,502.40078 30.61047,502.592221 30.9195395,502.592221 Z" id="Path" transform="translate(39.996419, 487.935288) scale(-1, 1) translate(-39.996419, -487.935288) "></path>
                    </g>
                </g>

            </svg>
            <svg width="20px" height="30px" slot="hooper-next">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" @click="resetCards()">
                    <g id="Carousel" transform="translate(-1390.000000, -473.000000)" fill="#FFFFFF">
                        <path d="M1390.92312,502.313867 L1399.66894,502.313867 C1400.00302,502.313867 1400.31504,502.147035 1400.50057,501.869205 L1409.62882,488.199536 C1409.85349,487.863091 1409.85331,487.424423 1409.62836,487.088166 L1400.50064,473.443968 C1400.31504,473.166538 1400.00326,473 1399.66948,473 L1390.97145,473 C1390.66296,473 1390.37968,473.170354 1390.23505,473.442847 C1390.08956,473.716964 1390.10103,474.047814 1390.26516,474.311196 L1398.58164,487.656933 L1398.58164,487.656933 L1390.31877,500.916629 C1390.14754,501.191405 1390.11409,501.530266 1390.22829,501.83322 C1390.3373,502.122426 1390.61405,502.313867 1390.92312,502.313867 Z" id="Path"></path>
                    </g>
                </g>
            </svg>
        </hooper-navigation>
    </hooper>
    </div>    
</template>


<script>

import { Hooper,  Navigation as HooperNavigation } from 'hooper';
const emitter = require('tiny-emitter/instance');
export default{
    name: 'Carousel',
    props:{
        index:Number,
        name: String
    },    
    components:{Hooper, HooperNavigation},
    data(){
        return{
            settings:{
                itemsToShow:1.25,
                infiniteScroll:true,
                wheelControl:false,
                centerMode:true,
                        breakpoints: {
                768: {
                    itemsToShow: 2,
                    centerMode:false,
                    pagination: 'fraction'
                },
                1024: {
                    itemsToShow: 3.5,
                    pagination: 'fraction'
                }
                }
            },
            cards: [],
            width: '',
            curCard: null
        }

    },
    mounted(){
        // list of cards AFTER clones have been created
        this.cards = this.$children[0].$children

        //necessary for identification since clones are generated by hooper
        this.cards.forEach((card,index) => {
                card.id = "carousel-card-"+ this.name + index
                card.show = false
              card.$el.id = "carousel-card-"+ this.name + index
            })
        emitter.on('toggle-click',this.toggle);
    },
    methods:{
        toggle(event){
            if (window.innerWidth >= 1024) {
                this.curCard = this.cards.find(card  => card.id == event.id)
                
                //resets all other card widths
                this.cards.filter(card => card != this.curCard).forEach(card => {
                    card.$el.classList.remove('auto')
                    card.show = false
                })
                
                //overrides hooper's set width while card is open
                if(this.curCard && !this.curCard.show) {
                   
                    this.curCard.$el.classList.add('auto')
                    this.curCard.show = true
                } else if ( this.curCard && this.curCard.show) {
                
                   this.curCard.$el.classList.remove('auto')
                    this.curCard.show = false
                }
                
            } 
           
        },
        resetCards() {
            if (window.innerWidth >= 1024) {
            //sets cards back to original state
            this.cards.forEach(card => {
                    card.$el.classList.remove('auto')
                    card.show = false
                    emitter.emit("toggle-click",{id:null,expand:false});
                })
            }
        }
    },
   
}



</script>