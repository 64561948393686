<template>
  <div></div>
</template>

<script>
/* eslint-disable */

export default {
  created () {
    window.addEventListener('scroll', this.toggleToaster);
  },
  destroyed () {
    window.removeEventListener('scroll', this.toggleToaster);
  },
  methods: {
    toggleToaster(event) {
      let scrollpos = window.scrollY
      const toaster = document.getElementsByClassName("toastNotification")
      const toaster_reveal = 500

      const add_class_on_scroll = () => toaster[0].classList.add("show")
      const remove_class_on_scroll = () => toaster[0].classList.remove("show")

      window.addEventListener('scroll', function() { 
          scrollpos = window.scrollY;

          if (scrollpos >= toaster_reveal) { add_class_on_scroll() }
          else { remove_class_on_scroll() }
      })
    }
  }
}
</script>