<template>
  <form class="search-bar" :action="searchPage" method="GET">
      <label for="search-input" class="sr-only">Search</label>
      <input id="search-input" name="search" :value="inputValue" placeholder="Search" tabindex="0"/>
      <button  type="submit" aria-label="Search">
        <slot></slot>
      </button>
  </form>
</template>

<script>
import SvgIcon from '../atoms/SvgIcon.vue';
import IconLockup from "./IconLockup.vue"

/* eslint-disable */
export default {
  components: { IconLockup, SvgIcon },
    data() {
        return {
            active: false,
            inputValue: '',
            searchPage: '/search-results'
        }
    },
};
</script>

<style scoped>
</style>
