<template>
  <h1 class="homeHero__hero--title">
    {{ text | replaceFilter }}
    <span class="after"></span>
  </h1>
</template>

<script>
export default {
  name: "NewLineBR",
  props: {
    //foo: this.$el.attributes['data-foo'].value,
    hero_title1: {
      type: String,
    },
    tag: {
      type: String,
      default: 'h1',
    },

  },
  data() {
    return {
      //text: "123Make It Here",
      text: this.hero_title1
    }

  },


  filters: {
    replaceFilter: function (string) {
      let regex = /([^\s]*\s[^\s]*)/;
      return string.replace(regex, "$1 \n ");

    }
  },
  render(createElement) {
    return createElement(this.tag , this.text )
  },
}
</script>





