<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    mounted() {
      let regex = /([^\s]*\s)/;
      this.$el.children[0].innerHTML = this.$el.children[0].innerHTML.replace(regex, "$1<br>")
    }
  }
</script>

<style lang="scss" scoped>

</style>





