<template>
    <div >
        <slot/>
    </div>
</template>

<script>
import { gsap,Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'GlobalAnimations',
  props: {
      transition: {
          type: String,
          default: "fadeInUp"
      }
  },
  mounted() {

     this.fadeInUp();
     this.fadeInUpLine();
     this.lineBorder();
     this.fadeInStaggered();
      
    


  },
  methods: {

    fadeInUp() {
        gsap.utils.toArray('.fade-in-up').forEach(function(item) {
                var anim = gsap.timeline({
            scrollTrigger: {
                trigger: item,
                toggleActions: 'restart none none reset',
                start: 'top 95%',
                // markers: true
            }
        
            })

            anim.from(item, {
                ease: Power2.out,
                y: 75,
                opacity:0,
                duration: .75,
            })
        })
    },
    fadeInUpLine() {
        gsap.utils.toArray('.fade-in-up-line').forEach(function(item) {
        var anim = gsap.timeline({
            scrollTrigger: {
                trigger: item,
                toggleActions: 'restart none none reset',
                start: 'top 95%',
                // markers: true
            }
            })

            anim.set(item.querySelector('.after'), {transformOrigin:"100% 50%"});

            anim.fromTo(item, {
                ease: Power2.out,
                y: 75,
                opacity:0,
                duration: .75,
            },{
                opacity:1,
                y:0
            }, 0)
            if( window.innerWidth < 768) {
                anim.fromTo(item.querySelector('.after'), {
                ease: Power2.out,
                width:'0px',
                duration: .75,
            },{
                width: '138px'
            }, 1)
            } else {
                anim.fromTo(item.querySelector('.after'), {
                ease: Power2.out,
                width:'0px',
                duration: .75,
            },{
                width: '269px'
            }, 1)
            }
            
        })
    },
    lineBorder() {
        gsap.utils.toArray('.line-border').forEach(function(item) {
        if(window.innerWidth >= 1024) {
            
            var anim = gsap.timeline({
                scrollTrigger: {
                trigger: item,
                toggleActions: 'restart none none reset',
                start: 'top 65%',
                // markers: true
                }
            })
            anim.set(item.querySelector('span'), {transformOrigin:"top"});
            anim.from(item.querySelector('span'), {
                ease: Power2.in,
                height:0,
                duration: 1.5,
            })
        }
        })
    },
    fadeInStaggered() {
        gsap.utils.toArray('.fade-in-staggered').forEach(function(item) {
            gsap.utils.toArray(item.children).forEach(function(card, index) {
               
                var anim = gsap.timeline({
                    scrollTrigger: {
                    trigger: card,
                    toggleActions: 'restart none none reset',
                    start: 'top 95%',
                    // markers: true
                    }
                })
                anim.fromTo(card, {
                    ease: Power2.in,
                    opacity:0,
                    y: 75,
                    duration: 1.5,
                    delay: 10*index
                }, {
                    opacity:1,
                    y:0
                }, .25 * index)
            })
        })
    }
  }
}
</script>
<style lang="scss">

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>