<template>
  <div :data-id="$parent.id" class="carousel-card" >
    <slot></slot>
  </div>
</template>

<script>
/* eslint-disable */
const emitter = require('tiny-emitter/instance');
export default {
  name: 'CarouselCardWrapper',
  props:{
      index: Number
  },
  data() {
    return {
      width: ''
    }
  },
  updated() {
    // triggered when the carousel shifts
    if(!this.id) {
       this.id = this.$parent.id
    }
    if(this.$el.classList.contains('expand')) {
        this.$el.classList.remove('expand')
        emitter.emit('close')
      }
  },
  mounted(){
      emitter.on('toggle-click',this.toggle)
  },
  
  methods: {
    //triggered when any card button is clicked
    toggle(event){
      if(this.$el.classList.contains('expand')) {
        this.$el.classList.remove('expand')
      } else if (this.$parent.id == event.id) {
        this.$el.classList.add('expand')
        
      }
     
    }
  },
}
</script>
<style scoped>
.event-click-handler{
  cursor: pointer;
}
</style>