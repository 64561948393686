<template>
  <transition
    name="slide"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script>
import Velocity from 'velocity-animate'

const duration = 250
const easing = 'ease-out'

export default {
  name: 'TransitionSlide',
  methods: {
    beforeEnter(el) {
      el.style.setProperty('will-change', 'width')
    },
    enter(el, complete) {
      Velocity(el, 'fadeIn', { easing, duration, complete })
    },
    afterEnter(el) {
      el.style.removeProperty('will-change')
    },
    beforeLeave(el) {
      el.style.setProperty('will-change', 'width')
    },
    leave(el, complete) {
      Velocity(el, 'fadeOut', { duration, easing, complete })
    },
    afterLeave(el) {
      el.style.removeProperty('will-change')
    },
  },
}
</script>
