import Vue from 'vue'
import '@/styles/tailwind.css'
import '@/styles/main.scss'
import 'postcss-object-fit-images'
import svg4everybody from 'svg4everybody'
import objectFitImages from 'object-fit-images'
import 'intersection-observer'
import SearchBar from '@/js/components/SearchBar'
import Accordion from '@/js/components/accordion'
import EventCardOpenCloseToggle from '@/js/components/EventCardOpenCloseToggle'
import SvgIcon from '@/js/atoms/SvgIcon'
import TransitionSlide from '@/js/components/transitions/TransitionSlide.vue'
import TransitionSlideOver from '@/js/components/transitions/TransitionSlideOver.vue'
import IconLockup from '@/js/components/IconLockup'
import ProfileButton from '@/js/components/ProfileButton'
import NewLineBR from "@/js/components/NewLineBR";
import NewLineFirstSpace from '@/js/components/NewLineFirstSpace'
import MegaMenuNavButton from '@/js/components/mega-menu/MegaMenuNavButton'
import MegaMenuContainer from '@/js/components/mega-menu/MegaMenuContainer'
import DisclosureButton from '@/js/components/disclosure/DisclosureButton'
import DisclosureContent from '@/js/components/disclosure/DisclosureContent'
import AppHeader from "@/js/components/AppHeader"
import Carousel from "@/js/components/carousel/Carousel"
import CarouselCardToggle from "@/js/components/carousel/CardToggle"
import CarouselCardWrapper from "@/js/components/carousel/CardWrapper"
import { Slide } from "hooper"
import DropdownList from "@/js/components/DropdownList"
import GlobalAnimations from "@/js/components/transitions/GlobalAnimations"
import MenuSlide from '@/js/components/transitions/MenuSlide'
import QuoteCarousel from '@/js/components/QuoteCarousel'
import FullHeightHeroCarousel from '@/js/components/FullHeightHeroCarousel'
import ToasterToggle from '@/js/components/ToasterNotification'
import YouTube from '@/js/components/video/YouTube'

Vue.config.productionTip = false

svg4everybody()
objectFitImages()

const Components = {
  GlobalAnimations,
  SearchBar,
  EventCardOpenCloseToggle,
  SvgIcon,
  TransitionSlide,
  TransitionSlideOver,
  IconLockup,
  ProfileButton,
  ...Accordion,
  NewLineBR,
  NewLineFirstSpace,
  MegaMenuNavButton,
  MegaMenuContainer,
  DisclosureButton,
  DisclosureContent,
  AppHeader,
  Carousel,
  CarouselCardToggle,
  Slide,
  CarouselCardWrapper,
  DropdownList,
  MenuSlide,
  QuoteCarousel,
  FullHeightHeroCarousel,
  ToasterToggle,
  YouTube,
}

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName])
})

new Vue().$mount('#app')
