<template>
  <transition
    name="menu-slide"
  >
    <slot />
  </transition>
</template>

<script>

export default {
  name: 'TransitionSlide',
  methods: {
   
  },
}
</script>
<style lang='scss' scoped>

.menu-slide-enter-active,
.menu-slide-leave-active {
  transition: all 0.25s ease-out;
}

.menu-slide-enter,
.menu-slide-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

</style>
