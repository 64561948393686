import AppAccordion from './AppAccordion'
import AppAccordionPanel from './AppAccordionPanel'
import AppAccordionHeader from './AppAccordionHeader'
import AppAccordionHeaderButton from './AppAccordionHeaderButton'

export default {
  AppAccordion,
  AppAccordionPanel,
  AppAccordionHeader,
  AppAccordionHeaderButton,
}
