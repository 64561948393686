<template>
    <a v-show="buttonVisible" @click="toggleVisible" class="cta btn__primary">See More</a>
</template>

<script>
export default {
    data() {
        return {
            buttonVisible: true
        }
    },
    methods: {
        toggleVisible() {
            const publications = document.getElementsByClassName("profile--publications--content");

            for (var i = 0; i < publications.length; i++){
                let item = publications[i]
                setTimeout(function(item) {
                 item.classList.add('show');
                }, 100 * i, item, i);
               
            }

            this.buttonVisible = false;
        }
    },
}
</script>


<style scoped>
    a {
        cursor: pointer;
    }
</style>