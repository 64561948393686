<template>
  <div class="event-click-handler" @click="clickHandler">
    <slot></slot>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'EventCardOpenCloseToggle',
  methods: {
    clickHandler(event){
      var bodyElement = event.target;
      while(!bodyElement.classList.contains('threeUpEventCards__body--object')){
        bodyElement = bodyElement.parentElement;
        if(bodyElement == null){
          return;
        }
      }
      bodyElement.classList.toggle('threeUpEventCards__body--active');
      Array.from(bodyElement.parentNode.children).forEach(
        (el) => {
          if(el != bodyElement) {
            el.classList.remove('threeUpEventCards__body--active');
            if(bodyElement.classList.contains('threeUpEventCards__body--active') && !el.classList.contains('threeUpEventCards__body--active')){
              el.classList.add('threeUpEventCards__body--inactive');
            }else{
              el.classList.remove('threeUpEventCards__body--inactive');
            }
          }
        }
      )
    }
  },
}
</script>
<style scoped>
.event-click-handler{
  cursor: pointer;
}
</style>