/* eslint-disable no-undef */
<template>
  <div class="youtube">
    <transition-group name="fade">
      <div key="2" v-show="!hidePlayer || !this.thumbnailSrc" class="youtube__iframe-wrapper">
        <div :id="playerId"></div>
      </div>
      <div
        key="1"
        v-if="hidePlayer"
        class="youtube__thumbnail-wrapper"
        @click="handleWrapperClick"
        aria-controls="playerId"
      >
        <img v-if="!this.$slots.thumbnail && this.thumbnailSrc" height="360" width="480" :src="thumbnailSrc" alt="" />
        <slot name="thumbnail"></slot>
      </div>

    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "100%"
    },
    width: {
      type: String,
      default: "100%"
    },
    videoId: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: [Boolean, String],
      required: false,
    },
  },
  data() {
    return {
      YTplayer: undefined,
      YTdone: false,
      YTframe: undefined,
      hidePlayer: true,
      playerState: undefined,
    };
  },
  computed: {
    playerId: function () {
      return "player_" + (Math.random() + 1).toString(36).substring(7);
    },
    thumbnailSrc: function () {
      if (this.thumbnail && typeof this.thumbnail !== "string") {
        return `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`;
      } else if (this.thumbnail && typeof this.thumbnail === "string") {
        return this.thumbnail;
      } else {
        return false;
      }
    },
  },
  created() {
    window.addEventListener("YT-Api-Ready", this.onYouTubeIframeAPIReady)
  },
  destroyed() {
    window.removeEventListener("YT-Api-Ready", this.onYouTubeIframeAPIReady);
  },
  mounted() {
    if (
      !Array.from(document.getElementsByTagName("script")).some((x) => {
        return x.id == "youtube_api";
      })
    ) {
      let YTapi = document.createElement("script");
      YTapi.setAttribute("id", "youtube_api");
      YTapi.setAttribute("src", "https://www.youtube.com/iframe_api");
      document.head.appendChild(YTapi);
      window.onYouTubeIframeAPIReady = ()=>{
        window.dispatchEvent(new Event("YT-Api-Ready"));
      }
    }
  },
  watch: {
    hidePlayer: function() {
      // eslint-disable-next-line no-undef
      if(this.hidePlayer && this.playerState === YT.PlayerState.PLAYING) {
        this.stopVideo();
      }
      // eslint-disable-next-line no-undef
      if(!this.hidePlayer && this.playerState !== YT.PlayerState.PLAYING) {
        this.playVideo();
    }
  },
  },
  methods: {
    handleWrapperClick(e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.YTplayer) {
        this.loadVideo();
      }
      this.hidePlayer = !this.hidePlayer;


    },
    onYouTubeIframeAPIReady() {
      this.loadVideo();
    },
    loadVideo() {
      // eslint-disable-next-line no-undef
      this.YTplayer = new YT.Player(this.playerId, {
        height: this.height,
        width: this.width,
        videoId: this.videoId,
        playerVars: {
          playsinline: 1,
          autoplay: 0,
          controls: 1,
          autohide: 1,
          showinfo : 0, // <- This part here
          wmode: 'opaque',
          rel: 0,
          loop: 0,
          modestbranding: 1,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerReady() {
    },
    onPlayerStateChange(event) {
      this.playerState = event.data;
      // eslint-disable-next-line no-undef
      if (event.data === YT.PlayerState.PLAYING) {
        this.hidePlayer = false;
      }
      // eslint-disable-next-line no-undef
      if (event.data === YT.PlayerState.PAUSED) {
        this.hidePlayer = true;
      }
    },
    stopVideo() {
      this.YTplayer.stopVideo();
    },
    playVideo() {
      if (!this.YTplayer) {
        this.loadVideo();
      }
      this.YTplayer.playVideo()  ;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
