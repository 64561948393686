<template>
  <div  :class="dropdownClass" :dropdownType="dropdownType">
      <div role="button" tabindex='0' class="dropdown-button"  @click="showList" @keydown="handleKeydown" >
            <slot name="button"></slot>
            <div class="arrow">
            <transition v-if="!show"  name="fade" mode="out-in" appear>
                <div>
                  <icon-lockup class="menu__label--caret" background-color="accent" color="accent" name="carotDown"></icon-lockup>
                </div>
            </transition>
            <transition v-if="show" name="fade" mode="out-in" appear>
                <div >
                    <icon-lockup  class="menu__label--caret" background-color="accent" color="accent" name="carotUp"></icon-lockup>
                </div>
            </transition>
            </div>
      </div>
    <transition  name="fade" mode="out-in" appear>
        <div v-show="show" class="dropdown-list" >
            <slot name="list" ></slot>
        </div>
        
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    dropdownClass: {
      type: String,
      default: 'dropdown'
    },
    dropdownType: {
        type: String,
        default: 'list'
    }
  },
  data() {
    return {
        show:false
    }
  },
  computed: {
   
  },
  mounted() {
      if(this.dropdownType == 'sort') {
          this.sort()
      }
  },
  methods: {
    
    showList() {
        this.show = !this.show
    },
    handleKeydown() {
    
      const { code } = event
     

      if (code == "Space" || code == "Enter") {
         event.preventDefault()

        this.show = !this.show
      }


     
    },
    sort() {
        var options = this.$el.lastElementChild.children
        
        for(var o = 0; 0 < options.length; o++) {
            options[o].addEventListener('click', (options)=> {
                this.$el.firstElementChild.firstElementChild.innerHTML = options.target.value
                this.showList();
            })

        }
    }
  },

}
</script>

<style lang="scss" scoped>

.dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.menu__label--caret:last-of-type {
    display:block;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.arrow {
    position: relative;
    height: 28px;
    display: flex;
    align-items: center;

    > div {
        position:absolute;
        right:0
    }
}


</style>