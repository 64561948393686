<script>
import eventBus from './util/eventBus'


export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    disclosureId: {
      type: String,
      required: true,
    },
    disclosureGroup: {
      type: String,
      required: false
    },
    noTransition: {
      type: Boolean,
      required: false,
      default: false
    },
    transition: {
      type: String,
      default: "TransitionSlide"
    },
    mode: {
      type: String,
      default: 'show',
      validate: value => [
        'if',
        'show',
      ].includes(value),
    },
  },
  data: () => ({
    expanded: undefined,
  }),
  created() {
    eventBus.$on('toggle', this.toggle)
    eventBus.$emit('created:container', this.disclosureId);
  },
  methods: {
    toggle({ controls, expanded, group }) {
      if (controls === this.disclosureId) {
        this.expanded = expanded
      } else if (controls !== this.disclosureId && group === this.disclosureGroup && expanded) {
        this.expanded = false
      }
    },
    createEl(h, data) {
      if (!(this.mode === 'show' || this.expanded)) {
        return undefined
      }
      if (this.noTransition) {
        return h(this.tag, data, this.$slots.default)
      } else {
        return h(this.transition, [h(this.tag, data, this.$slots.default)])
      }
    },
  },
  render(h) {
    const { expanded } = this
    const data = {
      attrs: {
        id: this.disclosureId,
      },
      class: { expanded },
    }

    if (this.mode === 'show') {
      data.directives = [{
        name: 'show',
        value: expanded,
      }]
    }

    return this.createEl(h, data)
  },
}
</script>
