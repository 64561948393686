<template>
  <button
    @keydown.esc="close"
    class="disclosure-button"
    v-bind:class="{ expanded: expanded, currentPage: currentPage }"
    type="button"
    :aria-haspopup="hasPopUp"
    :aria-controls="disclosureId"
    :aria-expanded="expanded.toString()"
    @click="handleClick"
    @mouseenter="hoverAction"
    @focus="focusAction"
  >
    <slot></slot>
    <!-- <IconLockup
      backgroundColor="black"
      color="white"
      name="carotDown"
    ></IconLockup> -->
  </button>
</template>

<script>
import eventBus from "./util/eventBus";

export default {
  components: {},
  props: {
    disclosureId: {
      type: String,
      required: true,
    },
    disclosureGroup: {
      type: String,
      default: undefined,
      required: false,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    openOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
      hasPopUp: false,
      currentPage: false,
    };
  },
  created() {
    eventBus.$on("created:container", () => {
      this.createdContainer();
    });
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });
    if (this.disclosureGroup == "mega-menu" && document.getElementById("mega-menu-nav__container") != null) {
      document
        .getElementById("mega-menu-nav__container")
        .addEventListener("mouseleave", () => {
          this.close();
        });

      if (this.$el.childNodes && this.$el.childNodes[0].tagName == "A") {
        let target = new URL(this.$el.childNodes[0].href).hostname;
        let current = new URL(window.location).hostname;
        if (target == current) {
          this.currentPage = true;
        }
      }
    }
  },
  destroy() {
    document.removeEventListener("mouseleave", () => {
      this.close();
    });
    window.removeEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    });
  },
  watch: {
    expanded() {
      eventBus.$emit("toggle", {
        expanded: this.expanded,
        controls: this.disclosureId,
        group: this.disclosureGroup,
      });
    },
  },
  computed: {
    focusAction: function () {
      if (this.openOnFocus) {
        return this.open;
      } else {
        return () => {};
      }
    },
  },
  methods: {
    hoverAction: function () {
      var that = this;

      if (this.openOnHover) {
        setTimeout(() => {
          if (this.$el.matches(":hover")) {
            that.open();
          }
        }, 500);
      }
    },
    handleClick: function () {
      this.expanded = !this.expanded;
    },
    createdContainer() {
      this.hasPopUp = true;
      this.$nextTick(() => {
        eventBus.$on("toggle", (event) => {
          this.toggle(event);
        });
      });
    },
    open() {
      if (!this.expanded) {
        this.expanded = true;
      }
    },
    close() {
      if (this.expanded) {
        this.expanded = false;
      }
    },
    toggle({ controls, expanded, group }) {
      if (controls === this.disclosureId) {
        this.expanded = expanded;
      } else if (
        controls !== this.disclosureId &&
        group === this.disclosureGroup &&
        expanded
      ) {
        this.expanded = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
