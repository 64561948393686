<template>
    <div :id="containerId" class="megaMenu" :class="containerStatus">
        <slot/>
    </div>
</template>

<script>
    export default {
        props: {
            menuItem: String,
        },
        data() {
            return {
                status: 'hidden',
            }
        },
        computed: {
            containerId: function() {
                return `megaMenu-${this.menuItem}`
            },
            containerStatus: function() {
                return `megaMenu-${this.status}`
            }
        },
        methods: {
            show: function(){
                this.status = 'active'
            },
            hide: function(){
                this.status = 'inactive'
            },
            toggle: function() {
                this.status = this.status === 'inactive'? 'active' : 'inactive';
            }
        },
        mounted() {
            this.$parent.megaMenu = this;
            // This is here to avoid MM blocks showing up visually before load is complete
            this.$nextTick(()=>{
                this.status = 'inactive';
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>