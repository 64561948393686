<template>
  <header class="nav-scroll-wrapper" :class="{expanded: expanded}">
    <slot></slot>
  </header>
</template>

<script>
export default {
  props: {
    hideOnScroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        expanded: true,
        scrollPosition: null,
        prevPostion: null,
        headerHeight: null
    }
  },
  computed: {
    scrollDirection: function () {
        if (this.scrollPosition < this.prevPostion) {
            return 'up'
        } else {
            return 'down'
        }
    }
  },
  watch: {

  },
  methods: {
    updateScroll: function() {
      this.scrollPosition = window.scrollY;
        if(this.scrollPosition > this.headerHeight && this.scrollDirection === 'down') {
            this.expanded = false;
        } else {
            this.expanded = true;
        }
      this.prevPostion = this.scrollPosition;
    },
    
  },

  mounted() {
    if (this.hideOnScroll) {
      window.addEventListener('scroll', this.updateScroll);
      this.headerHeight = this.$el.offsetHeight;

    }
  },
}
</script>

<style lang="scss" scoped>
.nav-scroll-wrapper {
    // position: absolute;
    // top: 0;
    transition: all .5s ease;
        visibility: hidden;
        opacity: 0;
        // top: -100%;
        transform: translateY(-100%);
    &.expanded {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);

    }
}

</style>