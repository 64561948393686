<template>
  <hooper :settings="quoteCarouselSettings" >
    <slot></slot>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
    <hooper-pagination slot="hooper-addons"></hooper-pagination>
  </hooper>
</template>

<script>
import { Hooper, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper'

export default {
  name: 'QuoteCarousel',
  components: {
    Hooper,
    HooperPagination,
    HooperNavigation
  },
  props: {
    index: Number,
  },
  data() {
    return {
      quoteCarouselSettings: {
        itemsToShow: 1,
        centerMode: true,
        pagination: 'yes',
        wheelControl: false
      },
    }
  },
}
</script>