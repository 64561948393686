<template>
  <div class="icon-lockup icon-lockup--link" v-if="link">
    <a
      class=""
      :href="this.href"
      :target="this.target"
      :rel="this.rel"
      :download="this.download"
    >
      <div class="base-icon-lockup icon__wrapper">
        <div :class="this.class">
          <svg-icon :name="name" />
        </div>
      </div>
    </a>
  </div>
  <div class="icon-lockup" v-else>
    <div class="base-icon-lockup icon__wrapper">
      <div :class="this.class">
        <svg-icon :name="name" />
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../atoms/SvgIcon.vue'
export default {
  components: { SvgIcon },
  name: 'IconLockup',
  props: {
    // Anchor Tag Props
    link: Boolean,
    href: String,
    rel: String,
    target: String,
    download: String,
    // Icon Props
    name: String,
    color: String,
    backgroundColor: String
  },
  computed: {
    class: function() {
      return `icon-container icon-${this.name} icon__color--${this.color || 'accent'} icon__backgroundColor--${this.backgroundColor || ((this.color == 'white' || this.color == 'light') ? 'dark' : 'light') }` 
    },
  },
}
</script>

<style scoped></style>
