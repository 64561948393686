<template>
  <div :ref="$parent.id" class="eventCard__body--icon bottom event-click-handler" @click="clickHandler">
    <icon-lockup v-if='expand'  name="close" color="list" background-color="light"></icon-lockup> 
    <icon-lockup v-else  name="plus" color="accent" background-color="light"></icon-lockup> 
  </div>
</template>

<script>
/* eslint-disable */
const emitter = require('tiny-emitter/instance');
export default {
  name: 'CarouselCardToggle',
  data() {
    return {
      expand: false
    }
  },
  computed: {
    
  },
  
  mounted() {

    emitter.on("toggle-click",this.toggle);
    emitter.on('close', this.close)
  },
  methods: {
    clickHandler(){
      console.log(this)
      //id on grandparent necessary for identification
      emitter.emit("toggle-click",{id:this.$parent.$parent.id,expand:this.expand});
    },
    toggle(event) {
      if(event.id == this.$parent.$parent.id) {
        this.expand = !this.expand
      } else {
        this.expand = false
      }
      
    },
    close() {
      this.expand = false
    }
  },
}
</script>
<style scoped>
.event-click-handler{
  cursor: pointer;
}
</style>