<template>
  <div aria-hidden="true"
    v-html="require(`@/views/atoms/svgs/icon-${name}.svg`)"
    class="svg-icon fill-current"
  ></div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  }
}
</script>
<style lang="scss">
.fill-current {
  svg,
  use,
  g {
    fill: currentColor;
  }

  
}

.icon__color--social .fill-current path[fill="#fff"],
.icon__color--social .fill-current path[fill="#FFF"],
.icon__color--social .fill-current path[fill="#ffffff"],
.icon__color--social .fill-current path[fill="#FFFFFF"],
.icon__color--social .fill-current path[fill="white"],
.icon__color--social .fill-current svg > g:only-child > path:only-child,
.icon__color--social .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--social .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child,
.icon__color--accent .fill-current path[fill="#fff"],
.icon__color--accent .fill-current path[fill="#FFF"],
.icon__color--accent .fill-current path[fill="#ffffff"],
.icon__color--accent .fill-current path[fill="#FFFFFF"],
.icon__color--accent .fill-current path[fill="white"],
.icon__color--accent .fill-current svg > g:only-child > path:only-child,
.icon__color--accent .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--accent .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child,
.icon__color--orange .fill-current path[fill="#fff"],
.icon__color--orange .fill-current path[fill="#FFF"],
.icon__color--orange .fill-current path[fill="#ffffff"],
.icon__color--orange .fill-current path[fill="#FFFFFF"],
.icon__color--orange .fill-current path[fill="white"],
.icon__color--orange .fill-current svg > g:only-child > path:only-child,
.icon__color--orange .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--orange .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child {
  fill: #bf5700 !important;
}

.icon__color--dark .fill-current path[fill="#fff"],
.icon__color--dark .fill-current path[fill="#FFF"],
.icon__color--dark .fill-current path[fill="#ffffff"],
.icon__color--dark .fill-current path[fill="#FFFFFF"],
.icon__color--dark .fill-current path[fill="white"],
.icon__color--dark .fill-current svg > g:only-child > path:only-child,
.icon__color--dark .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--dark .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child,
.icon__color--charcoal .fill-current path[fill="#fff"],
.icon__color--charcoal .fill-current path[fill="#FFF"],
.icon__color--charcoal .fill-current path[fill="#ffffff"],
.icon__color--charcoal .fill-current path[fill="#FFFFFF"],
.icon__color--charcoal .fill-current path[fill="white"],
.icon__color--charcoal .fill-current svg > g:only-child > path:only-child,
.icon__color--charcoal .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--charcoal .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child {
  fill: #333f48 !important;
}

.icon__color--light .fill-current path[fill="#fff"],
.icon__color--light .fill-current path[fill="#FFF"],
.icon__color--light .fill-current path[fill="#ffffff"],
.icon__color--light .fill-current path[fill="#FFFFFF"],
.icon__color--light .fill-current path[fill="white"],
.icon__color--light .fill-current svg > g:only-child > path:only-child,
.icon__color--light .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--light .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child,
.icon__color--white .fill-current path[fill="#fff"],
.icon__color--white .fill-current path[fill="#FFF"],
.icon__color--white .fill-current path[fill="#ffffff"],
.icon__color--white .fill-current path[fill="#FFFFFF"],
.icon__color--white .fill-current path[fill="white"],
.icon__color--white .fill-current svg > g:only-child > path:only-child,
.icon__color--white .fill-current svg > g:only-child > g:only-child > path:only-child,
.icon__color--white .fill-current svg > g:only-child > g:only-child > g:only-child > path:only-child {
  fill: #f5f4f0 !important;
}


</style>
